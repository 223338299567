import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Center,
  Skeleton,
  Image,
  Flex,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  Button,
  useToast,
  Badge,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { RiDatabase2Fill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import TablePagination from "../../components/common/tablePagination";
import { vehicleTypes } from "../../utils/helpers";
import { TbDotsVertical } from "react-icons/tb";
import moment from "moment/moment";
import { vehiclesService } from "../../services/vehicles";
import DepositModal from "./depositModal";

const FinanceTable = ({
  headers,
  data,
  loading,
  totalNum,
  pageNum,
  setSortBy,
  searching,
  itemsPerPage,
  onReload,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [driver, setDriver] = useState({});
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [closing, setClosing] = useState(false);
  const toast = useToast();
  useEffect(() => {
    setCurrentPage(pageNum);
  }, [pageNum]);

  const pagNation = useRef();

  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [showModal, setShowModal] = useState(false);

  return (
    <Box minH={"60vh"}>
      <Table className="customers-table">
        <Thead>
          <Tr>
            {headers?.map((header, index) => (
              <Th textTransform={"none"}>
                <Text fontWeight={"bold"}>{header?.name} </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            rows?.map((r) => {
              return (
                <>
                  <Tr>
                    {Array.from(
                      { length: headers?.length },
                      (_, i) => i + 1
                    )?.map((d) => {
                      return (
                        <Td>
                          <Box py={"7px"}>
                            <Skeleton
                              startColor={"#F4F4F4"}
                              borderRadius={"20px"}
                              endColor={"#dddddd"}
                              h={"20px"}
                            />
                          </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </>
              );
            })
          ) : (
            <>
              {!loading && data?.length == 0 ? (
                <>
                  <Tr _hover={{ bg: "white !important" }}>
                    <Td colSpan={12} bg={"white"} _hover={{ bg: "white" }}>
                      <Center bg={"white"} py={"45px"} w={"100%"} minW={"100%"}>
                        {searching ? (
                          <Text>No results found</Text>
                        ) : (
                          <>
                            <Center>
                              <Box textAlign={"center"}>
                                <Center mb={"18px"}>
                                  <Box
                                    py={6}
                                    px={6}
                                    borderRadius={"10px"}
                                    bg={"#F2F4F6"}
                                  >
                                    <RiDatabase2Fill
                                      color={"#bcc5d1"}
                                      size={35}
                                    ></RiDatabase2Fill>
                                  </Box>
                                </Center>
                                <Text
                                  fontSize={"19px"}
                                  fontWeight={500}
                                  mb={"9px"}
                                  color={"#637184"}
                                >
                                  Drivers
                                </Text>
                                <Text fontSize={"14px"} color="text.lightest">
                                  Drivers with pending deposits are currently
                                  unavailable. Once they are available, they
                                  will appear here.
                                </Text>
                              </Box>
                            </Center>
                          </>
                        )}
                      </Center>
                    </Td>
                  </Tr>
                </>
              ) : (
                <>
                  {data?.map((data) => {
                    return (
                      <Tr
                        cursor={"pointer"}
                        onClick={() => {
                          setDriver(data);
                          setShowModal(true);
                        }}
                      >
                        <Td>
                          <Flex alignItems={"center"} columnGap={"10px"}>
                            <Image
                              w={"60px"}
                              height={"50px"}
                              objectFit={"contain"}
                              src={data?.driver.lastVehicle?.images[0]}
                            />
                            <Box>
                              <Text>
                                {data?.driver.lastVehicle?.vehicle?.vehicleType}
                              </Text>
                              <Text>
                                {data?.driver?.lastVehicle?.plateNumber}
                              </Text>
                              <Text color="neutral.700">
                                {(data?.driver?.lastVehicle?.brand
                                  ? data?.driver?.lastVehicle?.brand
                                  : "") +
                                  (data?.driver?.lastVehicle?.brand
                                    ? ", "
                                    : "") +
                                  data?.driver?.lastVehicle?.model}
                              </Text>
                            </Box>
                          </Flex>
                        </Td>
                        <Td>
                          {data ? (
                            <Box>
                              <Text>{data?.driver?.fullName}</Text>
                              <Text color="neutral.700">
                                {data?.driver?.phoneNumber}
                              </Text>
                            </Box>
                          ) : (
                            <Box>
                              <Text>N/A</Text>
                            </Box>
                          )}
                        </Td>
                        <Td>
                          {data?.currency == "USD" ? "$" : data?.currency + " "}
                          {data?.totalAmount}
                        </Td>
                        <Td>
                          {data?.driver?.shiftStartDate ? (
                            <Box>
                              <Text>
                                {moment(
                                  new Date(data?.driver?.shiftStartDate)
                                ).format("DD/MM/YY hh:mm A")}
                              </Text>
                              <Text ml={"45px"}>{" | "}</Text>
                              <Text>
                                {data?.driver?.shiftEndDate
                                  ? moment(
                                      new Date(data?.driver?.shiftEndDate)
                                    ).format("DD/MM/YY hh:mm A")
                                  : "Shift not yet ended"}
                              </Text>
                            </Box>
                          ) : (
                            " - "
                          )}
                        </Td>
                        <Td>
                          <Box>
                            <Text>
                              {" "}
                              {data?.driver?.deposit?.length > 0
                                ? (data?.driver?.deposit[0]?.currency == "USD"
                                    ? "$"
                                    : data?.driver?.deposit[0]?.currency +
                                      " ") +
                                  "" +
                                  data?.driver?.deposit[0]?.amountPaid
                                : "$0"}
                            </Text>
                            <Text>
                              {" "}
                              {data?.driver?.deposit?.length > 0
                                ? moment(
                                    new Date(
                                      data?.driver?.deposit[0]?.createdAt
                                    )
                                  ).format("DD/MM/YY hh:mm A")
                                : "No deposit"}
                            </Text>
                          </Box>
                        </Td>
                        <Td>
                          <Badge
                            textTransform={"capitalize"}
                            color={"#fff"}
                            bg="#F43A4D"
                          >
                            {"Not deposited"}
                          </Badge>
                        </Td>
                        <Td onClick={(e) => e.stopPropagation()}>
                          <Menu>
                            <MenuButton bg={"transparent"} as={Button}>
                              <TbDotsVertical size={22} />
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  setDriver(data);
                                  setShowModal(true);
                                }}
                              >
                                View more
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Tbody>
      </Table>

      {totalNum > itemsPerPage ? (
        <Box ref={pagNation} className="pag-cont bottom-0 w-full pb-20">
          <TablePagination
            length={totalNum}
            initialPage={parseInt(currentPage)}
            currentItems={data?.length}
            pageNum={pageNum}
            itemsPerPage={itemsPerPage}
            setPage={(page) => {
              setCurrentPage(parseInt(page));
              navigate(`/finance?page=${page}`);
            }}
          />
        </Box>
      ) : (
        <></>
      )}

      {showModal && (
        <DepositModal
          driver={driver}
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          onFinish={() => {
            setShowModal(false);
            toast({
              position: "top",
              title: "Success",
              status: "success",
              description: "Deposit made successfully!",
              isClosable: true,
              duration: 2000,
            });
            onReload();
          }}
        />
      )}
    </Box>
  );
};

export default FinanceTable;

import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Skeleton,
  Flex,
} from "@chakra-ui/react";
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ReasonsModal from "./reasonModal";
import { reasonsService } from "../../services/requestReasons";
import { BsThreeDotsVertical } from "react-icons/bs";

const RequestReasons = () => {
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReason, setSelectedReason] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState(0);

  const fetchReasons = async () => {
    setLoading(true); // Set loading state to true
    try {
      const response = await reasonsService.getAllReasons(
        activeTab == 0 ? "PASSENGER" : "DRIVER"
      );
      setReasons(response.data);
    } catch (error) {
      console.error("Error fetching reasons", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReasons();
  }, [activeTab]);

  const handleAddReason = () => {
    setSelectedReason(null);
    onOpen();
  };

  const handleEditReason = (reason) => {
    setSelectedReason(reason);
    onOpen();
  };

  const handleDeleteReason = async (id) => {
    // Confirm deletion with a message
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this request cancel reason? This action cannot be undone."
    );
    if (!confirmDelete) return;

    try {
      await reasonsService.deleteReason(id);
      fetchReasons();
    } catch (error) {
      console.error("Error deleting reason", error);
    }
  };

  return (
    <Box p={5}>
      <Flex
        mb={"10px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        columnGap={10}
      >
        <Flex columnGap={5}>
          <Button
            borderBottomColor={activeTab == 0 ? "#3e98d4" : undefined}
            borderBottomWidth={activeTab == 0 ? 2 : undefined}
            onClick={() => setActiveTab(0)}
          >
            Passenger
          </Button>
          <Button
            borderBottomColor={activeTab == 1 ? "#3e98d4" : undefined}
            borderBottomWidth={activeTab == 1 ? 2 : undefined}
            onClick={() => setActiveTab(1)}
          >
            Driver
          </Button>
        </Flex>

        <Button
          leftIcon={<AiOutlinePlus />}
          colorScheme="primary"
          onClick={handleAddReason}
          mb={4}
        >
          Add Reason
        </Button>
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Reason</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading
            ? // Display skeletons while loading
              Array.from({ length: 5 }).map((_, rowIndex) => (
                <Tr key={rowIndex}>
                  {Array.from({ length: 3 }).map((_, colIndex) => (
                    <Td key={colIndex}>
                      <Box py={"7px"}>
                        <Skeleton
                          startColor={"#F4F4F4"}
                          borderRadius={"20px"}
                          endColor={"#dddddd"}
                          h={"20px"}
                        />
                      </Box>
                    </Td>
                  ))}
                </Tr>
              ))
            : reasons.map((reason) => (
                <Tr key={reason.id}>
                  <Td>{reason.label}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<BsThreeDotsVertical />}
                      />
                      <MenuList>
                        <MenuItem
                          icon={<AiOutlineEdit />}
                          onClick={() => handleEditReason(reason)}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          icon={<AiOutlineDelete />}
                          color="red.500"
                          onClick={() => handleDeleteReason(reason.id)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
        </Tbody>
      </Table>
      {isOpen && (
        <ReasonsModal
          isOpen={isOpen}
          onClose={onClose}
          fetchReasons={fetchReasons}
          reason={selectedReason}
        />
      )}
    </Box>
  );
};

export default RequestReasons;

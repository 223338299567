import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { reasonsService } from "../../services/requestReasons";

const targets = ["PASSENGER", "DRIVER"];

const ReasonsModal = ({ isOpen, onClose, fetchReasons, reason }) => {
  const [label, setLabel] = useState("");
  const [target, setTarget] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (reason) {
      setLabel(reason.label || "");
      setTarget(reason.target || ""); // Ensure target is pre-filled if editing
    } else {
      setLabel("");
      setTarget("");
    }
  }, [reason]);

  const handleSave = async () => {
    if (!label || !target) {
      toast({
        title: "All fields are required.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      if (reason) {
        await reasonsService.updateReason(reason.id, {
          label,
          target: target?.toUpperCase(),
        });
        toast({
          title: "Reason updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await reasonsService.createReason({
          label,
          target: target?.toUpperCase(),
        });
        toast({
          title: "Reason created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      fetchReasons();
      onClose();
    } catch (error) {
      toast({
        title: "Error saving reason.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{reason ? "Edit Reason" : "Add Reason"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="label" mb={4} isRequired>
            <FormLabel>Reason</FormLabel>
            <Input
              placeholder="Enter reason"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </FormControl>
          <FormControl id="target" mb={4} isRequired>
            <FormLabel>Target</FormLabel>
            <Select
              placeholder="Select target"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
            >
              {targets.map((t) => (
                <option key={t} value={t}>
                  {t}
                </option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            mr={3}
            onClick={onClose}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            onClick={handleSave}
            isLoading={isLoading}
            loadingText="Saving"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReasonsModal;

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  Text,
  Box,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
const SessionModal = ({ isOpen, onClose }) => {
  return (
    <Modal size={"md"} closeOnOverlayClick={false} isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={"15px"}>
        <ModalBody py={"20px"} px={"20px"} pb={'25px'}>
          <Center>
            <Box>
              <Text
                color={"#2F313F"}
                fontWeight={'bold'}
                fontSize={"22px"}
              >
            Session Expired
              </Text>
              <Center my={"20px"} mt={'15px'}>
                <Text color={"#121d2b99"} fontSize={"16px"}>
                  Your session has expired. Please login again to continue accessing your dashboard.
                </Text>
              </Center>

              <Flex justifyContent={'flex-end'}>
              <Button onClick={()=>{
                window.location.href = ("/admin/logout")
              }} backgroundColor={'black'} px={'40px'} _hover={{backgroundColor: 'darkgray'}} color={'white'}>Login</Button>
              </Flex>
            </Box>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SessionModal;

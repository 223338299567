import React, { useEffect, useState } from 'react';
import SessionModal from './session-modal';

const SessionManager = ({children}) => {
  const [showDialog, setShowDialog] = useState(false);

  const INACTIVITY_TIMEOUT = 300000; // 5 minute in milliseconds
  const MAX_SESSION_DURATION = 7200000; // 2 hours in milliseconds

  const handleActivity = () => {
    let isAuthenticated = false;
    const token = localStorage.getItem("access_token");
    if(!token){
     return;
    }else{
        isAuthenticated = true;
    }
    if(isAuthenticated){

        const lastActivity = localStorage.getItem("lastActivity");
        if(lastActivity){
            const currentTime = Date.now();
            const inactiveTime = currentTime - lastActivity;
            const sessionDuration = currentTime - lastActivity;
      
            if (inactiveTime >= INACTIVITY_TIMEOUT || sessionDuration >= MAX_SESSION_DURATION) {
              isAuthenticated = false;
              localStorage.removeItem("access_token");
              return;
            }
        }

    console.log("activity",Date.now())
    localStorage.setItem("lastActivity", Date.now());
    }
  };

  useEffect(() => {
    let checkSession;
    // Add event listeners for user activity
    const events = ['mousedown', 'keydown', 'scroll', 'mousemove', 'touchstart'];
    events.forEach(event => {
      document.addEventListener(event, handleActivity);
    });

    let isAuthenticated = false;
    const token = localStorage.getItem("access_token");
    if(!token){
     return;
    }else{
        isAuthenticated = true;
     checkSession = setInterval(() => {
        const lastActivity = localStorage.getItem("lastActivity");
        if(lastActivity){
            const currentTime = Date.now();
            const inactiveTime = currentTime - lastActivity;
            const sessionDuration = currentTime - lastActivity;
      
            if (inactiveTime >= INACTIVITY_TIMEOUT || sessionDuration >= MAX_SESSION_DURATION) {
              setShowDialog(true);
              localStorage.removeItem("access_token");
              isAuthenticated = false;
              return;
            }
        }else{
    localStorage.setItem("lastActivity", Date.now());
        }
    }, 1000);
}

return () => {
    events.forEach(event => {
      document.removeEventListener(event, handleActivity);
    });
    if(checkSession){
    clearInterval(checkSession);
    }
  };

  }, []);

  return (
  <>{children}
  <SessionModal isOpen={showDialog} onClose={()=>{
    setShowDialog(false)
  }} />
  </>
  );
};

export default SessionManager;